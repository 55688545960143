<template>
    <div>
        <Details :data="state.object.data" />
        <History :history="state.history" />
    </div>
</template>

<script>
    import { store } from '@/store/BusinessObject.store.js';
    import  { pricingStore } from '@/components/businessObjects/pricing/Pricing.store';
    import History from "@/components/businessObjects/utils/History";
    import Details from '@/components/businessObjects/pricing/Details';

    export default {
        name: 'Container',
        components: {
            Details,
            History
        },
        data() {
            return {
                state: store.state,
                pricingState: pricingStore.state
            }
        },
        created() {
            store.initialize(this.pricingState);
            this.load();
        },
        methods: {
            load: function () {
                store.load('');
                store.getHistory();
            }
        }
    }
</script>

<style scoped>

</style>