export const pricingStore = {
	state: {
		name: 'pricing',
		title: 'Pricing',
		titlePlural: 'Pricing',
		nav: {
			base: 'Pricing',
			newView: false,
			sideNav: false,
			views: [],
		},
		search: {},
		object: {
			fields: []
		}
	}
}